/* eslint-disable react/no-danger */
import Img from 'gatsby-image/withIEPolyfill';
import React, { useState } from 'react';
import tt from '../../helpers/translation';
import Buttons from '../Buttons/Buttons';
import Modal from '../Modals/Modal/Modal';
import './cardHotelPassesExpress.scss';

const CardHotelPassesExpress = ({
  data: { type, title, logo, priceDay, bottomText, alt },
  locale,
}) => {
  const [isOpen, setOpen] = useState(false);

  const handleModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <div className="vidar-container">
      <Modal isOpen={isOpen} data={bottomText[1]} title={title} closeModal={handleCloseModal} />
      <div className={`vidar-top ${type}`}>
        <div className="vidar-logo-title">
          <Img fluid={logo} alt={alt} className="vidar-img" />
          <h4 className="title-vidar" dangerouslySetInnerHTML={{ __html: title }} />
        </div>
      </div>

      <div className="vidar-contain">
        <div className="container-text">
          <div className="prices-container">
            <div className="prices">
              <div className="price">{priceDay}€</div>
              <span className="day">
                {title === 'CARIBE PREMIUM'
                  ? tt('2 PERSONAS / DÍA', locale)
                  : tt('PERSONA/DIA', locale)}
              </span>
            </div>
          </div>
          <div className="bottom-text" dangerouslySetInnerHTML={{ __html: bottomText[0] }} />
          <div className="bottom-btn">
            <Buttons
              onClick={handleModal}
              size="alone"
              color="white"
              text={tt('Más info', locale)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardHotelPassesExpress;
