import { graphql } from 'gatsby';
import React, { Component } from 'react';
import { Asgard } from '../src/components/Asgard/Asgard';
import CardHotelPassesExpress from '../src/components/CardHotelPassesExpress/CardHotelPassesExpress';
import Heimdall from '../src/components/Heimdall/Heimdall';
import Hod from '../src/components/Hod/Hod';
import Layout from '../src/components/Layout/Layout';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import Thor from '../src/components/Thor/Thor';
import H3 from '../src/components/Titles/TitleH3/TitleH3';
import SEO from '../src/helpers/seo';
import tt from '../src/helpers/translation';
import './pase_express.scss';

export const pageQuery = () => graphql`
  query hotelExpressPass($locale: String!) {
    allHotelExpressHeaderBlock(filter: { tag: { eq: "hotelExpress" }, lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
          image
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allDistHotelExpressBreadCrumbBlock(
      filter: { tag: { eq: "hotelExpress" }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          destination_url
          name
        }
      }
    }
    allHotelExpressVidarTitle(filter: { tag: { eq: "hotelExpress" }, lang: { eq: $locale } }) {
      edges {
        node {
          title
        }
      }
    }
    allHotelExpressVidarCards(filter: { tag: { eq: "hotelExpress" }, lang: { eq: $locale } }) {
      edges {
        node {
          title
          mainText
          bottomText
          price1
          price2
          possibleFilters
          position
          alt
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allHotelExpressBottomText(filter: { tag: { eq: "hotelExpress" }, lang: { eq: $locale } }) {
      edges {
        node {
          title
        }
      }
    }
    allHotelExpressSeoData(filter: { tag: { eq: "hotelExpress" }, lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allHotelExpressRichData(filter: { tag: { eq: "hotelExpress" }, lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            og_title
            rich_markup_type
            og_description
          }
        }
      }
    }
  }
`;

class HotelPaseExpress extends Component {
  state = {
    once: false,
    throttleInterval: 150,
    offset: 0,
    partialVisibility: false,
    world: '',
    tipoServicio: '',
    tipoMenu: '',
    initialFilterStateMundo: true,
    initialFilterStateMenu: true,
    initialFilterStateServicios: true,
  };

  handleFilter = (name, tag) => {
    if (tag === tt('Parque', this.props.pageContext.locale)) {
      const newFilterData = { ...this.state.filterData, tipoServicio: name };
      this.setState({
        tipoServicio: name,
        initialFilterStateMenu: false,
        filterData: newFilterData,
      });
    } else if (tag === tt('Temporada', this.props.pageContext.locale)) {
      const newFilterData = { ...this.state.filterData, tipoMenu: name };
      this.setState({
        tipoMenu: name,
        initialFilterStateServicios: false,
        filterData: newFilterData,
      });
    }
  };

  resetFilter = () => {
    const newFilterData = new Object();
    this.setState({
      initialFilterStateMundo: true,
      initialFilterStateMenu: true,
      initialFilterStateServicios: true,
      filterData: newFilterData,
    });
  };

  render() {
    const heimdallData = {
      name: this.props.data.allHotelExpressHeaderBlock.edges[0].node.title,
      subtitle: this.props.data.allHotelExpressHeaderBlock.edges[0].node.description,
      image: {
        url: this.props.data.allHotelExpressHeaderBlock.edges[0].node.localImage.childImageSharp
          .fluid,
      },
    };

    const thorData = {
      title: tt(
        'PUEDES ADQUIRIR TUS TICKETS EXPRESS EN LOS SIGUIENTES PUNTOS DE VENTA',
        this.props.pageContext.locale
      ),
      anchor: true,
      cards: [
        {
          Icon: 'call37',
          titleIcon: tt('En la recepción del hotel donde te alojas', this.props.pageContext.locale),
        },
        {
          Icon: 'imac',
          titleIcon: tt(
            'Durante el proceso de reserva de los hoteles de PortAventura World',
            this.props.pageContext.locale
          ),
        },
      ],
    };

    const title = {
      title: this.props.data.allHotelExpressVidarTitle.edges[0].node.title,
    };

    const vidarData = () => {
      const vidarCards = this.props.data.allHotelExpressVidarCards.edges.map((vidarCard) => {
        const [park] = vidarCard.node.possibleFilters.split(' ');
        return {
          type: park,
          title: vidarCard.node.title,
          order: vidarCard.node.position,
          logo: vidarCard.node.localImage.childImageSharp.fluid,
          mainText: vidarCard.node.mainText,
          bottomText: vidarCard.node.bottomText.split('<hr />'),
          priceDay: vidarCard.node.price1,
          possibleFilters: vidarCard.node.possibleFilters,
        };
      });

      return vidarCards.filter((card) => {
        const setFilters = [];
        for (const prop in this.state.filterData) setFilters.push(this.state.filterData[prop]);
        if (!setFilters.length) return card;
        if (setFilters.every((elem) => card.possibleFilters.indexOf(elem) > -1)) return card;
      });
    };

    const AsgardData = {
      image:
        'https://s3-eu-west-1.amazonaws.com/portaventura-world-production-files/wilson_cms/images/images/000/010/686/modal_width/paw-web-2.png',
      text: `${vidarData().length} ${tt('Productos', this.props.pageContext.locale)}`,
      filters: [
        {
          tag: tt('Parque', this.props.pageContext.locale),
          options: [
            { value: tt('PortAventura Park', this.props.pageContext.locale), label: 'PA' },
            {
              value: tt('Caribe Aquatic Park', this.props.pageContext.locale),
              label: 'CAP',
            },
            { value: tt('Ferrari Land', this.props.pageContext.locale), label: 'FL' },
          ],
        },
        {
          tag: tt('Temporada', this.props.pageContext.locale),
          options: [
            { value: tt('Temporada Alta', this.props.pageContext.locale), label: 'TT' },
            {
              value: tt('Temporada Media', this.props.pageContext.locale),
              label: 'TM',
            },
            {
              value: tt('Temporada Baja', this.props.pageContext.locale),
              label: 'TB',
            },
          ],
        },
      ],
    };

    const bottomText = {
      text: this.props.data.allHotelExpressBottomText.edges[0].node.title,
    };

    const vidarCardsOrd = vidarData().sort((a, b) => a.order - b.order);

    return (
      <Layout
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
        layoutFromOld
      >
        <SEO
          title={this.props.data.allHotelExpressSeoData.edges[0].node._0.title}
          description={this.props.data.allHotelExpressSeoData.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <Hod
          data={this.props.data.allHotelExpressRichData.edges[0].node.rich_markup}
          url={this.props.pageContext.url}
          img={this.props.data.allHotelExpressHeaderBlock.edges[0].node.image}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="general-index">
            <div className="pase-express-container">
              <div className="thor-aesir-container">
                <NeoAesir
                  locale={this.props.pageContext.locale}
                  pageName={this.props.pageContext.pageName}
                  title={this.props.data.allDistHotelExpressBreadCrumbBlock.edges[0].node.name}
                />
                <Thor data={thorData} />
              </div>
              <H3 data={title} />
              <div className="Asgard-box">
                <Asgard
                  locale={this.props.pageContext.locale}
                  data={AsgardData}
                  filterStatusTipoMenu={this.state.initialFilterStateMenu}
                  filterStatusTipoServicios={this.state.initialFilterStateServicios}
                  resetFilter={this.resetFilter}
                  filterChange={this.handleFilter}
                />
              </div>
              <div className="passes-container">
                {vidarCardsOrd.map((card) => (
                  <CardHotelPassesExpress
                    locale={this.props.pageContext.locale}
                    key={card.title}
                    data={card}
                  />
                ))}
              </div>
              <div id="anchor" className="bottom-text-container">
                <div
                  className="bottom-text"
                  dangerouslySetInnerHTML={{ __html: bottomText.text }}
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default HotelPaseExpress;
